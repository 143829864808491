<template>
  <v-row class="mr-0 mt-15" id="step1-right-page">
    <v-col cols="6" class="pr-6">
      <v-text-field placeholder="First Name" hide-details="auto" v-model.trim="firstName" @input="searchData"
        :error-messages="$helpers.errorMsg('name', valid.model.first_name, 'First Name')"></v-text-field>
    </v-col>
    <v-col cols="6" class="pr-6">
      <v-text-field placeholder="Last Name" hide-details="auto" v-model.trim="lastName" @input="searchData"
        :error-messages="$helpers.errorMsg('name', valid.model.last_name, 'Last Name')"></v-text-field>
    </v-col>
    <v-col cols="6" class="pr-6">
      <v-text-field placeholder="Suburb" hide-details="auto" v-model.trim="model.suburb" @input="searchData"></v-text-field>
    </v-col>
    <v-col cols="6" class="pr-6">
      <div class="pt-3">
        <VuePhoneNumberInput class="phone-input" :no-example="true" :error="model.phoneErr" error-color="#949494" color="#949494" valid-color="#949494"
          @update="phoneUpdate($event, 'ph')" v-model.trim="model.phone" default-country-code="AU" @input="searchData"></VuePhoneNumberInput>
      </div>
    </v-col>
    <v-col cols="6" class="pr-6">
      <v-menu v-model="didYouMeanOpen" offset-y :disabled="!didYouMeanOpen" rounded="b-xl">
          <template v-slot:activator="{ on, attrs }">
              <v-text-field placeholder="Email" hide-details="auto" v-model.trim="model.email" @input="experianEmailSuggest()"
                :error-messages="$helpers.errorMsg('email', valid.model.email, 'Email')" v-bind="attrs" v-on="on"
                @blur="experianEmailSuggest"></v-text-field>
          </template>
          <v-list>
              <v-list-item-title class="font-weight-bold pl-2">Did you mean:</v-list-item-title>
              <v-list-item @click="model.email = item;didYouMeanOpen=false;searchData();" class="pa-0 min-h-auto my-1" v-for="(item, index) in didYouMeanLists" :key="index">
                  <v-list-item-title class="secondary--text font-weight-bold pl-3">{{ item }}</v-list-item-title>
              </v-list-item>
          </v-list>
      </v-menu>
    </v-col>
    <v-col cols="6" class="pr-6">
      <div class="pt-3">
        <VuePhoneNumberInput class="mobile-input" :no-example="true" :error="model.mobileErr" error-color="#949494" color="#949494" valid-color="#949494"
          @update="phoneUpdate($event, 'mob')" v-model.trim="model.mobile" default-country-code="AU" @input="searchData"></VuePhoneNumberInput>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import _ from 'lodash';
import { mapActions } from "vuex";

export default {
  name: "stepOne",
  props: ["valid"],
  components: {
    VuePhoneNumberInput
  },
  data() {
    return {
      phErr: false,
      mobErr: false,
      didYouMeanOpen: false,
      didYouMeanLists: [],
      emailValidLoading: false,
      timoutId: { searchTimeOut: null, emailTimeOut: null}
    }
  },
  computed: {
    model: {
      get() {
        return this.$store.getters["model"];
      },
      set(newValue) {
        return this.$store.commit("setModel", newValue);
      },
    },
    firstName: {
      get() {
        return this.model.first_name ? _.startCase(_.toLower(this.model.first_name)) : '';
      },
      set(newValue) {
        this.model.first_name = newValue.trim();
      },
    },
    lastName: {
      get() {
        return this.model.last_name ? _.startCase(_.toLower(this.model.last_name)) : '';
      },
      set(newValue) {
        this.model.last_name = newValue.trim();
      }
    }
  },
  created() {
    this.$store.commit("setFollowUpModel", {});
    this.model = {...{
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        mobile: "",
        suburb: "",
        countryCode: {
            code: "61",
        },
        phoneErr: false,
        mobileErr: false}, ...this.model};
  },
  mounted() {
    (document.getElementsByClassName("select-country-container")[0]).style.maxWidth = '90px';
    (document.getElementsByClassName("select-country-container")[0]).style.minWidth = '90px';
    (document.getElementsByClassName("select-country-container")[1]).style.maxWidth = '90px';
    (document.getElementsByClassName("select-country-container")[1]).style.minWidth = '90px';
    (document.getElementsByClassName("country-selector__label")[0]).style.display = 'none';
    (document.getElementsByClassName("country-selector__label")[1]).style.display = 'none';
    (document.getElementsByClassName("country-selector__country-flag")[0]).style.top = '15px';
    (document.getElementsByClassName("country-selector__country-flag")[1]).style.top = '15px';
    (document.getElementsByClassName("country-selector__toggle")[0]).style.top = 'calc(45% - 10px)';
    (document.getElementsByClassName("country-selector__toggle")[1]).style.top = 'calc(45% - 10px)';
    (document.getElementsByClassName("country-selector__input")[0]).style.paddingTop = '0px';
    (document.getElementsByClassName("country-selector__input")[1]).style.paddingTop = '0px';
    (document.getElementsByClassName("country-selector")[0]).getElementsByTagName('label')[0].style.display = 'none';
    (document.getElementsByClassName("select-country-container")[0]).style['min-width'] = '95px';
    const phoneEl = (document.getElementsByClassName("phone-input")[0]).getElementsByClassName('input-phone-number')[0];
    phoneEl.getElementsByTagName('input')[0].placeholder = phoneEl.getElementsByTagName('label')[0].innerText = 'Phone';
    const mobileEl = (document.getElementsByClassName("mobile-input")[0]).getElementsByClassName('input-phone-number')[0];
    mobileEl.getElementsByTagName('input')[0].placeholder = mobileEl.getElementsByTagName('label')[0].innerText = 'Mobile';
  },
  methods: {
    ...mapActions([
      "getSearchLists",
      "getEmailSuggestion"
    ]),
    searchData() {
      if (this.timoutId.searchTimeOut) {
        clearTimeout(this.timoutId.searchTimeOut);
        this.timoutId.searchTimeOut = false;
      }
      const self = this;
      this.timoutId.searchTimeOut = setTimeout(async () => {
        self.$store.dispatch('cancelPendingRequests');
        if (self.model.first_name || self.model.last_name || self.model.email || self.model.phone || self.model.mobile) {
          self.$store.commit("setSelectedDonor", {});
          const reqData = {
            first_name: self.model.first_name,
            last_name: self.model.last_name,
            email: self.model.email,
            phone: self.model.phone,
            mobile: self.model.mobile,
            limit: 50
          }
          self.timoutId.searchTimeOut = false;
          self.getSearchLists(reqData);
        } else {
            self.$store.commit("setSearchLists", []);
        }
        if (!self.model.onairOutTouched) {
          self.model.donor_infos = {};
          self.model.donor_infos.first_name = self.model.first_name;
        }
        if (!self.model.receiptTouched) {
          self.model.receipt_name = `${self.model.first_name} ${self.model.last_name}`;
        }
        self.timoutId.searchTimeOut = false;
      }, 500);
    },
    phoneUpdate(data, field) {
      if (field === 'ph') {
        if (!data.isValid && data.phoneNumber) {
          this.model.phoneErr = true;
        } else {
          this.model.phoneErr = false;
        }
      } else {
        if (!data.isValid && data.phoneNumber) {
          this.model.mobileErr = true;
        } else {
          this.model.mobileErr = false;
        }
      }
      this.$store.commit("setModel", this.model);
    },
    experianEmailSuggest(evt=null) {
      if (this.timoutId.emailTimeOut) {
        clearTimeout(this.timoutId.emailTimeOut);
        this.timoutId.emailTimeOut = false;
      }
      const self = this;
      this.timoutId.emailTimeOut = setTimeout(async () => {
        self.$store.dispatch('cancelPendingRequests');
        self.didYouMeanOpen = false;
        self.didYouMeanLists = [];
        if (self.valid.model.email.email && self.model.email && !self.emailValidLoading) {
            self.emailValidLoading = true;
            this.getEmailSuggestion(self.model.email).then((resp) => {
                if(resp && resp.result && resp.result.did_you_mean) {
                    self.didYouMeanLists = resp.result.did_you_mean || [];
                    if (resp.result.confidence !== 'verified' && self.didYouMeanLists.length) self.didYouMeanOpen = true;
                }
                self.timoutId.emailTimeOut = false;
                self.emailValidLoading = false;
                if(!evt) self.searchData();
            }).catch(err => {
                self.timoutId.emailTimeOut = false;
                self.emailValidLoading = false;
            });
        } else  {
            if(!evt) self.searchData();
        }
        if (self.valid.model.email.email && self.model.email && self.didYouMeanLists.length) self.didYouMeanOpen = true;
        self.timoutId.emailTimeOut = false;
      }, 500);
    }
  }
};
</script>
